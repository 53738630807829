import axios from "axios";
import {
	ConversationLinkedModelType,
	IConversationMessageSource,
	IUserConversation,
	UserConversationType
} from "~/utils/conversation";
import { Component, Page } from "~/utils/page";
import { getCurrentActivePlan, Plan } from "~/utils/user";
import { ULTIMATE_PLAN } from "~/utils/premium_plans";

interface ConversationSettings {
	youtube_search_disabled: boolean;
	suggestions_disabled: boolean;

	autoPlayAudio: boolean; //default is probably always undefined
}

export const useConversation = () => {
	const pastConversations = useState<IUserConversation[]>("past_conversations", () => []);
	const pastConversationsLoaded = useState<boolean>("past_conversations_loaded", () => false);
	const currentLinkedTextString = useState<string[]>("current_linked_text_string", () => []);
	const toLinkComponents = useState<Component[]>("to_link_components", () => []);
	const toLinkPages = useState<Page[]>("to_link_pages", () => []);

	async function createConversation(modelId: string, modelType: ConversationLinkedModelType, convoType: UserConversationType): Promise<IUserConversation | null> {
		try {
			const { data } = await axios.post("/conversation/new_conversation", {
				linked_model_id: modelId,
				linked_model_type: modelType,
				type: convoType,
			});

			getPosthog().capture("conversation_created", {
				type: convoType,
			});

			return data;
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	async function fetchPastConversations() {
		try {
			const { data } = await axios.get("/conversation/past_conversations");

			pastConversations.value.push(...data);
		} catch (e) {
			addAlert("An issue occurred while fetching your past conversations.", "error");
			console.error(e);
		} finally {
			pastConversationsLoaded.value = true;
		}
	}

	function appendLinkedTextString(text: string) {
		currentLinkedTextString.value.push(text);
		getPosthog().capture("linked_text_string_appended", {
			text,
		});
	}

	function getMessages(conversationType: UserConversationType, conversationModelId: string) {
		return useState<IConversationMessage[]>(`conversation-messages-${conversationType}-${conversationModelId}`, () => []);
	}

	function linkComponent(component: Component) {
		if (toLinkComponents.value.find((c) => c._id === component._id)) {
			return;
		}
		toLinkComponents.value.push(component);

		getPosthog().capture("component_linked_to_conversation", {});
	}

	function linkPage(page: Page) {
		if (toLinkPages.value.find((p) => p._id === page._id)) {
			return;
		}
		toLinkPages.value.push(page);

		getPosthog().capture("page_linked_to_conversation", {});
	}

	const accruedLimit = useCookie<number>("accruedLimit", {
		expires: getTonightAtMidnight(),
	});
	const conversationOpen = useBrowserState("conversationOpen", false);

	const settings = useBrowserState<ConversationSettings>("conversationSettings", {
		youtube_search_disabled: false,
		suggestions_disabled: false,
		autoPlayAudio: false,
	});

	if (accruedLimit.value === undefined) {
		accruedLimit.value = 0;
	}

	function getTonightAtMidnight() {
		const now = new Date();
		return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
	}

	const hasReachedDailyLimit = computed(() => {
		return accruedLimit.value >= getDailyLimit();
	});

	function toggleConversation() {
		conversationOpen.value = !conversationOpen.value;
	}

	function getDailyLimit() {
		const plan = getCurrentActivePlan();

		if (!plan) {
			return 0;
		}

		if (plan === Plan.FREE) {
			return FREE_PLAN.assistant_messages;
		} else if (plan === Plan.PLUS) {
			return PRO_PLAN.assistant_messages;
		} else if (plan === Plan.ULTIMATE) {
			return ULTIMATE_PLAN.assistant_messages;
		}

		return 0;
	}

	async function toggleSourceDisabled(msg: IConversationMessageSource, msgType: ConversationUserMessageType, disabled: boolean) {
		msg.source_disabled = disabled;
		await axios.post("/conversation/source_toggle", {
			messageId: msg._id,
			userMessageType: msgType,
			sourceDisabled: disabled,
		});
	}

	return {
		createConversation,
		pastConversations,
		currentLinkedTextString,
		appendLinkedTextString,
		fetchPastConversations,
		linkPage,
		linkComponent,
		pastConversationsLoaded,
		toLinkComponents,
		toLinkPages,
		accruedLimit,
		hasReachedDailyLimit,
		getDailyLimit,
		getTonightAtMidnight,
		toggleSourceDisabled,
		toggleConversation,
		getMessages,
		conversationOpen,
		settings,
	};
};
