import * as pdfjs from "pdfjs-dist";
import { PageViewport, PDFDocumentProxy, PDFPageProxy, RenderTask, TextLayer } from "pdfjs-dist";
import axios from "axios";
import { GetViewportParameters } from "pdfjs-dist/types/src/display/api";

export class PDFTask<T> {
	value: T;

	constructor(value: T) {
		this.value = value;
	}
}

enum PDFState {
	READABLE,
	DESTROYED,
}

export class PDFPage {
	index: number;
	isRendered: boolean;
	value?: PDFPageProxy;

	textLayer?: TextLayer;

	constructor(index: number) {
		this.index = index;
		this.isRendered = false;
		this.value = undefined;
		this.textLayer = undefined;
	}
}

export class PDF {
	renderTasks: PDFTask<RenderTask>[];
	textRenderTasks: PDFTask<Promise<undefined>>[];
	pageFetchTasks: PDFTask<Promise<PDFPageProxy>>[];

	state: PDFState;
	pdf: PDFDocumentProxy;

	size: number;

	shouldBeDestroyed: boolean;

	pages: PDFPage[];

	constructor(pdf: PDFDocumentProxy) {
		this.pdf = pdf;
		this.renderTasks = [];
		this.textRenderTasks = [];
		this.pageFetchTasks = [];

		this.state = PDFState.READABLE;
		this.shouldBeDestroyed = false;
		this.size = pdf.numPages;

		this.pages = Array.from({ length: this.size }, (_, i) => new PDFPage(i));
	}

	getViewport(index: number, params: GetViewportParameters): PageViewport {
		if (!this.pages[index]) {
			throw Error(`Page not found, the index ${index} is out of bounds`);
		}
		if (!this.pages[index].value) {
			throw Error(`Page is not rendered, attempted to get viewport of page ${index}: `);
		}
		return this.pages[index].value!!.getViewport(params);
	}

	isPageRendered(index: number): boolean {
		if (!this.pages[index]) {
			throw Error(`Page not found, the index ${index} is out of bounds`);
		}
		return this.pages[index].isRendered;
	}

	async loadPage(index: number): Promise<void> {
		const page = this.pages[index];
		const fetchTask = new PDFTask<Promise<PDFPageProxy>>(toRaw(this.pdf).getPage(index + 1));
		this.pageFetchTasks.push(fetchTask);

		page.value = await fetchTask.value;

		this.pageFetchTasks.splice(this.pageFetchTasks.indexOf(fetchTask), 1);
	}

	async renderPage(index: number, viewport: PageViewport, canvas: HTMLCanvasElement): Promise<void> {
		const page = this.pages[index];
		let proxy = toRaw(page.value);

		if (!proxy) {
			await this.loadPage(index);
			proxy = toRaw(this.pages[index].value!!);
		}

		try {
			const task = proxy.render({
				canvasContext: canvas.getContext("2d")!,
				viewport,
			});

			let pdfTask = new PDFTask<RenderTask>(task);
			this.renderTasks.push(pdfTask);
			let promise = task.promise;

			promise.then(() => {
				page.isRendered = true;

				this.renderTasks.splice(this.renderTasks.indexOf(pdfTask), 1);
			});

			return promise;
		} catch (e) {
			console.error(e);
		}

		return Promise.resolve();
	}

	async renderTextLayer(index: number, viewport: PageViewport, container: HTMLElement): Promise<void> {
		const page = this.pages[index];
		let proxy = toRaw(page.value);

		if (!proxy) {
			await this.loadPage(index);
			proxy = toRaw(this.pages[index].value!!);
		}
		try {
			if (!page.textLayer) {
				page.textLayer = new TextLayer({
					container: container,
					viewport,
					textContentSource: proxy.streamTextContent({
						includeMarkedContent: true,
						disableNormalization: false,
					}),
				});
				const render = toRaw(page.textLayer).render();
				const task = new PDFTask<Promise<undefined>>(render);
				this.textRenderTasks.push(task);
				try {
					await render;
				} catch (e) {
					console.error(e);
				}
				this.textRenderTasks.splice(this.textRenderTasks.indexOf(task), 1);
			} else {
				toRaw(page.textLayer).update({
					viewport,
				});
			}
		} catch (e) {
			console.error(e);
		}
	}
}

export namespace PDFSystem {
	const activePDFs: PDF[] = [];

	export async function setPDFWorker() {
		pdfjs.GlobalWorkerOptions.workerSrc = "/lib/pdf.worker.min.mjs";
	}

	export async function getPDF(pdf_id: string): Promise<PDF> {
		await setPDFWorker();

		const response = await fetch(`${axios.defaults.baseURL}/pdf_component/data?id=${pdf_id}`, {
			cache: 'default', // Uses browser's caching based on server headers
		});

		if (!response.ok) {
			throw new Error(`Failed to fetch PDF with id ${pdf_id}: ${response.statusText}`);
		}

		const arrayBuffer = await response.arrayBuffer();
		const data = new Uint8Array(arrayBuffer);

		try {
			const doc = await pdfjs.getDocument({ data }).promise;
			const active = new PDF(doc);
			activePDFs.push(active);
			return active;
		} catch (error) {
			console.error(`Error loading PDF with id ${pdf_id}:`, error);
			throw error;
		}
	}

	export async function performCleanup() {
		for (const pdf of activePDFs) {
			if (!pdf.shouldBeDestroyed) continue;

			for (const task of pdf.renderTasks) {
				if (task.value) {
					task.value.cancel();
				}
			}
			if (pdf.textRenderTasks.length > 0) {
				continue;
			}
			if (pdf.pageFetchTasks.length > 0) {
				continue;
			}

			await pdf.pdf.cleanup();
			await pdf.pdf.destroy();
			pdf.state = PDFState.DESTROYED;

			activePDFs.splice(activePDFs.indexOf(pdf), 1);
		}
	}
}
