<template>
	<input
		ref="selector"
		:accept="SUPPORTED_IMAGE_COMPONENT_MIME_TYPES_AS_STRING"
		class="hidden"
		type="file"
		@change="uploadFileFromSelector()" />
		<div v-if="uploadingFile">
			<div
				ref="backdrop"
				aria-hidden="true"
				class="dark-curtains fixed inset-0"
				style="z-index: 40"></div>
			<div
				ref="container"
				aria-modal="true"
				class="inset-center-xy fixed"
				style="z-index: 99999999999999">
				<div class="w-full max-w-md rounded-lg bg-white px-6 py-4 shadow-lg">
					<h1 class="text-xlg font-medium">Uploading PDF...</h1>
				</div>
			</div>
		</div>
</template>
<script lang="ts" setup>
import { SUPPORTED_IMAGE_COMPONENT_MIME_TYPES, SUPPORTED_IMAGE_COMPONENT_MIME_TYPES_AS_STRING, uploadSoloFile } from "~/utils/component/component_utils";
import { addAlert } from "~/composables/useAlert";
import { MAX_FILE_SIZE, MAX_FILE_SIZE_IN_MB } from "~/utils/magic_numbers";

const selector = ref(null) as Ref<HTMLInputElement | null>;
const emits = defineEmits(["openCallback"]);

const props = defineProps<{
	dragAndDrop: boolean;
}>();

onMounted(() => {
	emits("openCallback", openUploadInput);

	if (props.dragAndDrop) {
		window.addEventListener("drop", handleDrop);
		window.addEventListener("dragover", handleDragOver);
	}
});

onUnmounted(() => {
	window.removeEventListener("drop", handleDrop);
	window.removeEventListener("dragover", handleDragOver);
});

function handleDragOver(e: DragEvent) {
	e.preventDefault();
	e.stopPropagation();
}

function handleDrop(e: DragEvent) {
	e.preventDefault();
	e.stopPropagation();

	const files = e.dataTransfer?.files;

	if (!files) {
		return;
	}

	for (const file of files) {
		uploadFile(file);
	}
}

function openUploadInput() {
	selector.value?.click();
}

const uploadingFile = ref(false);

function uploadFileFromSelector() {
	if (!selector.value) return;
	if (uploadingFile.value) return;
	if (!selector.value) return;

	if (!selector.value!!.files || selector.value!!.files.length === 0) {
		addAlert("No file selected.", "error");
		return;
	}

	const file = selector.value!!.files!![0];
	uploadFile(file);
}


async function uploadFile(file: File) {
	if (uploadingFile.value) {
		return;
	}

	if (!SUPPORTED_IMAGE_COMPONENT_MIME_TYPES.includes(file.type)) {
		addAlert("Unsupported file type.", "error");
		return;
	}

	if (file.size > MAX_FILE_SIZE) {
		addAlert(`You cannot upload a file larger than ${MAX_FILE_SIZE_IN_MB}mb.`, "error");
		return;
	}

	uploadingFile.value = true;
	try {
		const component = await uploadSoloFile(file);

		if (component.content.file?.fileType === "pdf") {
			await navigateTo(`/pdf/${component._id}`);
		}

		addAlert("File uploaded successfully", "success");
	} catch (e) {
		addAlert("There was an error uploading the file, the file might be too large.", "error");
		console.error(e);

		//clear the input
		if (selector.value) {
			selector.value.value = "";
		}
	} finally {
		uploadingFile.value = false;
	}
}
</script>
