<template>
	<div>
		<button
			class="flex flex-row items-center space-x-2 text-base font-semibold text-gray-600"
			@click="isOpen = !isOpen">
			<ArchiveBoxIcon class="h-3.5 w-3.5" />
			<span class="text-gray-600">Archive</span>
		</button>
		<dropdown
			v-if="isOpen"
			class="-mt-8 ml-[230px]"
			width="250px"
			@close="isOpen = false">
			<div class="px-2 py-1.5">
				<h1 class="font-bold">Archived pages</h1>
				<div class="styled-input-with-icon mt-1">
					<input
						ref="searchInput"
						v-model="search"
						placeholder="Search archived pages"
						type="text" />
					<SearchIcon class="h-4 w-4" />
				</div>
			</div>
			<div class="h-64 overflow-y-auto">
				<dropdown-item
					v-for="page of archivedPages"
					:key="page._id"
					class="!py-1 text-left"
					@click="navigateTo(`/page/${page._id}`)">
					<div class="flex w-full flex-row items-center justify-between text-xs">
						<p>
							{{ page.emoji ? page.emoji + " " : "" }}
							{{ page.title ? page.title : "Untitled" }}
						</p>
						<button
							class="rounded-md p-1 hover:bg-gray-100"
							@click="
								unarchivePage(page);
								isOpen = false;
							">
							<Undo class="h-4 w-4" />
						</button>
					</div>
				</dropdown-item>
			</div>
		</dropdown>
	</div>
</template>
<script lang="ts" setup>
import { ArchiveBoxIcon } from "@heroicons/vue/24/outline";
import { SearchIcon, Undo } from "lucide-vue-next";
import { PageSyncPipeline } from "~/utils/sync/page_sync_pipeline";
import syncPageUpdate = PageSyncPipeline.syncPageUpdate;

const { pages } = usePages();

const search = ref("");
const searchInput = ref<HTMLInputElement | null>(null);
const isOpen = ref(false);

watchEffect(() => {
	if (searchInput.value) {
		searchInput.value.focus();
	}
});

const archivedPages = computed(() => {
	const filter = pages.value.filter((page) => page.archived);
	filter.sort((a, b) => (a.lastOpened > b.lastOpened ? -1 : 1));

	if (search.value) {
		return fastStringSearch(search.value, filter, (page) => (page.title ? page.title : "Untitled"), {
			limit: 20,
		});
	}

	return filter;
});

function unarchivePage(page: Page) {
	page.archived = false;

	syncPageUpdate(page);
}
</script>
