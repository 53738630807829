import { ComponentType, IFile } from "./page";

export enum UserConversationType {
	page_chat = "page_chat",
	flashcard_full_screen_chat = "flashcard_full_screen_chat",
	flashcard_study_chat = "flashcard_study_chat",
	pdf_chat = "pdf_chat",
	global_scope_chat = "global_scope_chat",
}

export enum ConversationUserMessageType {
	REGULAR_MESSAGE = "REGULAR_MESSAGE",
	FILE_UPLOAD = "FILE_UPLOAD",
	LINK_COMPONENT = "LINK_COMPONENT",
	LINK_PAGE = "LINK_PAGE",
}

export enum ConversationLinkedModelType {
	page = "page",
	pdf = "pdf",
	flashcard = "flashcard",
	user = "user",
}

export interface IUserConversation {
	_id: string;
	type: UserConversationType;
	user_id: string;
	created_at: Date;

	last_used_at: Date;

	title: string | null;
	summary: string | null;

	linked_model_id: string;
	linked_model_type: ConversationLinkedModelType;

	folder_id: string | null; // uploaded PDFs & Images (not files) will be stored in a folder to ensure it's not overwhelming

	save(): Promise<void>;
}

export enum UserConversationMessageSenderType {
	user = "user",
	assistant = "assistant",
}

export enum UserConversationMessageStatus {
	not_finished = "not_finished",
	finished = "finished",
}

export interface YTVideo {
	id: string;
	title: string;
	description: string;
	url: string;
	thumbnail: string;
}

export interface IConversationMessage {
	_id: string;
	user_id: string;

	conversation_id: string;
	conversation_type: UserConversationType;

	message_text: string;
	created_at: Date;

	sender: {
		type: UserConversationMessageSenderType;
		sender_id: string;
	};
}

export interface IConversationMessageUser extends IConversationMessage {
	linked_text_strings: string[] | null;
}

export type IConversationMessageUserWithType = IConversationMessageUser & {
	user_message_type: ConversationUserMessageType;
};

export interface IConversationMessageSource extends IConversationMessage {
	source_disabled?: boolean;
}

export interface IConversationLinkComponentMessage extends IConversationMessageSource {
	component_id: string;
	component_type: ComponentType; //In case I wanna do flashcards in the future aswell

	is_pdf: boolean; //just easy to check if it's a pdf or not
}

export interface IConversationLinkPageMessage extends IConversationMessageSource {
	page_id: string;
}

export enum FileDataType {
	text = "text",
	image = "image",
	pdf = "pdf", //will have a special parser
}

export interface IConversationMessageUserUploadFile extends IConversationMessageSource {
	file: IFile;

	data: {
		type: FileDataType;
		text_content: string | null; //Only applicable for text files
	};
}

export enum ConversationAssistantActionType {
	youtube_search = "youtube_search",
}

export interface IConversationAssistantAction {
	action_type: ConversationAssistantActionType;
}

export interface IConversationAssistantActionYoutubeSearch extends IConversationAssistantAction {
	query: string;
	results: YTVideo[];
}

export enum AIMessageRating {
	positive = "positive",
	negative = "negative",
	no_rating = "no_rating",
}

export interface IConversationMessageAI extends IConversationMessage {
	generation_status: UserConversationMessageStatus;

	model_information: {
		id: string; //GPT3.5, or GPT4

		total_input_tokens: number;
		total_output_tokens: number;

		create_start_time: Date;
		create_end_time: Date;

		time_taken: number;
	};

	rating: {
		rated_at: Date;
		rating: AIMessageRating;
	};

	actions?: IConversationAssistantAction[];
}

// CONTEXT

export enum ConversationContextType {
	PAGE = "page",
	COMPONENT = "component",
	PDF_PAGE = "pdf_page",
}

export interface IConversationContext {
	_id: string;
	user: string; //ObjectId
	conversationId: string; //ObjectId
	conversationMessageId: string; //ObjectId
	contextText: string;
	createdAt: Date;

	type: ConversationContextType;
	encodingId: string;

	data: any;
}

export interface IConversationPDFPageContext extends IConversationContext {
	data: {
		pageNumber: number;
		componentId: string; //ObjectId
	};
}

export interface IConversationPageContext extends IConversationContext {
	data: {
		pageId: string; //ObjectId
	};
}

export interface IConversationComponentContext extends IConversationContext {
	data: {
		componentId: string; //ObjectId
		pageId?: string; //ObjectId
	};
}

// SUGGESTIONS

export enum ConversationSuggestionType {
	PAGE_TITLE_UPDATE = "page_title_update", //When the title the user currently has doesn't match the title of the page
}

export enum ConversationSuggestionStatus {
	ACTIVE = "active",
	ACCEPTED = "accepted",
	REJECTED = "rejected",
}

export enum ConversationSuggestionScope {
	CONVERSATION_AGNOSTIC = "conversation_agnostic",
	CONVERSATION_SPECIFIC = "conversation_specific",
}

export interface IConversationSuggestion {
	type: ConversationSuggestionType;

	created_at: Date;
	updated_at: Date;
	status: ConversationSuggestionStatus;

	user_id: string;
	conversation_type: UserConversationType;

	scope: ConversationSuggestionScope;
	conversation_id: string | null;

	metadata: any;
}
