import revive_payload_client_4sVQNw7RlN from "/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/workspace/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/workspace/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/workspace/.nuxt/sentry-client-config.mjs";
import _1_polyfill_plugin_client_Z2M92olqH5 from "/workspace/plugins/1_polyfill_plugin.client.ts";
import auth_plugin_PWbcmTcUGJ from "/workspace/plugins/auth_plugin.ts";
import axios_error_plugin_client_MF58BfPzqW from "/workspace/plugins/axios_error_plugin.client.ts";
import background_components_fetch_plugin_client_JXbTxPapdG from "/workspace/plugins/background_components_fetch_plugin.client.ts";
import client_data_fetch_client_eQeAcTVelI from "/workspace/plugins/client_data_fetch.client.ts";
import client_sync_start_plugin_client_zXBqDx044L from "/workspace/plugins/client_sync_start_plugin.client.ts";
import home_data_fetch_interval_client_7y8bxYzpZP from "/workspace/plugins/home_data_fetch_interval.client.ts";
import invalid_domain_redirect_q3F41HUnZJ from "/workspace/plugins/invalid_domain_redirect.ts";
import jwt_refresh_plugin_client_KXzCiHC3su from "/workspace/plugins/jwt_refresh_plugin.client.ts";
import page_sync_pipeline_notif_plugin_client_TWAzhdvquf from "/workspace/plugins/page_sync_pipeline_notif_plugin.client.ts";
import pdf_cleanup_plugin_client_I7nnIXBeH4 from "/workspace/plugins/pdf_cleanup_plugin.client.ts";
import popupcontent_plugin_client_eKPb7M1eFx from "/workspace/plugins/popupcontent_plugin.client.ts";
import posthog_plugin_client_E2vvlHRhQt from "/workspace/plugins/posthog_plugin.client.ts";
import theme_plugin_client_UK6a321ju9 from "/workspace/plugins/theme_plugin.client.ts";
import tracking_plugin_client_zXhZoio4eJ from "/workspace/plugins/tracking_plugin.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  _1_polyfill_plugin_client_Z2M92olqH5,
  auth_plugin_PWbcmTcUGJ,
  axios_error_plugin_client_MF58BfPzqW,
  background_components_fetch_plugin_client_JXbTxPapdG,
  client_data_fetch_client_eQeAcTVelI,
  client_sync_start_plugin_client_zXBqDx044L,
  home_data_fetch_interval_client_7y8bxYzpZP,
  invalid_domain_redirect_q3F41HUnZJ,
  jwt_refresh_plugin_client_KXzCiHC3su,
  page_sync_pipeline_notif_plugin_client_TWAzhdvquf,
  pdf_cleanup_plugin_client_I7nnIXBeH4,
  popupcontent_plugin_client_eKPb7M1eFx,
  posthog_plugin_client_E2vvlHRhQt,
  theme_plugin_client_UK6a321ju9,
  tracking_plugin_client_zXhZoio4eJ
]