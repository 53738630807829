import axios from "axios";
import { Component, ComponentFlashCards, ComponentType, ImageComponent, Page } from "~/utils/page";
import ObjectID from "bson-objectid";
import { pageUpdatePipeline } from "~/utils/pipeline/model_update_pipeline";
import { getPosthog } from "~/utils/posthog_utils";
import { PageSyncPipeline } from "~/utils/sync/page_sync_pipeline";
import { RecentFilters, RecentItem } from "~/utils/recents";

export const usePages = () => {
	const pages = useState<Page[]>("pages", () => []);
	const loadingPages = useState<boolean>("loading-pages", () => true);
	const specialComponents = useState<Component[]>("special-components", () => []); //PDFs, Images, Flashcards, (Includes both components that are inside a page, and not inside

	const fetchPages = async () => {
		const { serverPagesData } = useAuth();

		if (!serverPagesData.value) {
			loadingPages.value = true;
			const { data } = await axios.get("/pages/components/many_unarchived");

			serverPagesData.value = data;
		}

		parseFetchedPagesData(serverPagesData.value);
		loadingPages.value = false;

		serverPagesData.value = null; //just purge it to save mem
	};

	const parseFetchedPagesData = (data: any) => {
		if (!data) {
			return;
		}
		if (typeof data === "string") {
			data = JSON.parse(data);
		}
		if (!data.components) {
			return;
		}
		for (const p of data.components) {
			const components = getFullComponents(p.pageId);

			components.value = p.components;

			haveComponentsBeenFetched(p.pageId).value = true;
		}

		pages.value = data.pages;
		specialComponents.value = data.specialComponents;

		//Set Children
		for (const page of pages.value) {
			page.children = pages.value.filter((p) => p.parent === page._id && !p.archived).sort((a, b) => a.order - b.order);

			if (!page.children) {
				page.children = [];
			}
		}
	};

	const getAllComponents = computed(() => {
		const map = new Map<string, Component>();

		for (const page of pages.value) {
			const components = getFullComponents(page._id).value;

			for (const c of components) {
				map.set(c._id, c);
			}
		}

		for (const c of specialComponents.value) {
			if (!map.has(c._id)) {
				map.set(c._id, c);
			}
		}

		return Array.from(map.values()).filter((x) => x != undefined && x !== null);
	});

	const getRecents = computed(() => {
		const recents: RecentItem[] = [];

		pages.value.forEach((page) => {
			recents.push({
				type: RecentFilters.PAGES,
				title: page.title || "Untitled Page",
				createdAt: page.createdAt,
				openedAt: new Date(page.lastOpened),
				url: `/page/${page._id}`,
				model: page,
			});
		});

		for (let c of getAllComponents.value) {
			const page = getParentPage(c);

			switch (c.type) {
				case ComponentType.FLASH_CARDS:
					recents.push({
						type: RecentFilters.FLASHCARDS,
						title: (c as ComponentFlashCards).content.title || "Untitled Flashcards",
						createdAt: c.createdAt,
						openedAt: new Date(c.lastOpenedAt || c.createdAt),
						url: `/page/${page?._id}/flashcard/${c._id}`,
						model: c,

						parentPageId: page?._id,
					});
					break;
				case ComponentType.IMAGE:
					if (!c.content.file) continue;
					if (c.content.file.id.endsWith(".pdf")) {
						recents.push({
							type: RecentFilters.PDFS,
							title: (c as ImageComponent).content.file?.name || "Untitled PDF",
							createdAt: c.createdAt,
							openedAt: new Date(c.lastOpenedAt || c.createdAt),
							url: `/pdf/${c._id}`,
							model: c,

							parentPageId: page?._id,
						});
					} else {
						recents.push({
							type: RecentFilters.IMAGE,
							title: (c as ImageComponent).content.file?.name || "Untitled Image",
							createdAt: c.createdAt,
							openedAt: new Date(c.lastOpenedAt || c.createdAt),
							url: c.content.url || "",
							model: c,

							parentPageId: page?._id,
						});
					}
					break;
			}
		}

		return recents.sort((a, b) => b.openedAt.getTime() - a.openedAt.getTime());
	});

	const getFullComponents = (pageId: string) => {
		return useState<Component[]>(`page-components-${pageId}`, () => []);
	};

	const haveComponentsBeenFetched = (pageId: string) => {
		return useState<boolean>(`page-components-${pageId}-fetched`, () => false);
	};

	const preloadComponents = async (page: Page) => {
		const components = getFullComponents(page._id);
		const fetchedComponents = haveComponentsBeenFetched(page._id);

		try {
			components.value = (await axios.get("/pages/" + page._id + "/components")).data;

			fetchedComponents.value = true;
		} catch (e) {
			console.error(e);
		}
	};

	const getPage = (id: string) => {
		return pages.value.find((page) => page._id === id);
	};

	const getParent = (page: Page) => {
		return pages.value.find((p) => p._id === page.parent);
	};

	const tryGetLocalComponent = (pageId: string, componentId: string): Component | null => {
		const components = getFullComponents(pageId).value;
		const found = components.find((c) => c._id === componentId);

		if (found) {
			return found;
		}

		//check special components
		for (const c of specialComponents.value) {
			if (c._id === componentId) {
				return c;
			}
		}

		return null;
	};

	const getComponent = async (pageId: string, componentId: string): Promise<Component> => {
		const local = tryGetLocalComponent(pageId, componentId);

		if (local) {
			return local;
		}

		const res = await axios.get(`/pages/${pageId}/components/${componentId}`);

		return res.data as Component;
	};

	const getComponentSolo = async (componentId: string): Promise<Component> => {
		const noPromise = getComponentSoloNoPromise(componentId);

		if (noPromise) {
			return noPromise;
		}

		const res = await axios.get(`/pages/components/${componentId}`);

		return res.data as Component;
	};

	const getComponentSoloNoPromise = (componentId: string): Component | undefined => {
		for (const page of pages.value) {
			const components = getFullComponents(page._id).value;
			const found = components.find((c) => c._id === componentId);

			if (found) {
				return found;
			}
		}

		const loneComponent = specialComponents.value.find((c) => c._id === componentId);

		if (loneComponent) {
			return loneComponent;
		}

		return undefined;
	};

	const getParentPage = (component: Component) => {
		return pages.value.find((p) => p.components.includes(component._id));
	};

	const duplicatePage = async (id: string, redirectToPage: boolean) => {
		try {
			const { data } = await axios.post(`/pages/${id}/duplicate`);
			pages.value.push(data);

			if (redirectToPage) {
				await navigateTo(`/page/${data._id}`);
			}

			getPosthog().capture("page_create", {
				page_id: data._id,
			});
			getPosthog().capture("page_duplicated", {
				page_id: data._id,
			});

			addAlert("Page duplicated", "success");
			return data;
		} catch (error) {
			addAlert("Failed to duplicate page", "error");
			console.error(error);
		}
	};

	const createPage = (parent?: Page, title: string = "", redirect: boolean = true) => {
		const { user } = useAuth();

		const page: Page = {
			_id: ObjectID().toHexString(),
			ownedBy: user.value!!._id,
			title: title,
			emoji: null,
			createdAt: new Date(),
			lastUpdated: new Date(),
			archived: false,
			archivedAt: null,
			lastOpened: new Date(),
			order: pages.value.length + 1,
			parent: parent ? parent._id : null,
			sharingStatus: {
				visibility: "private",
				editors: [],
				publicToSearchEngines: false,
				sharedWith: [],
			},
			components: [],
			children: [],
			relationshipCategories: [],
			aiDescription: null,
		};

		pageUpdatePipeline.markPending(page._id);
		pages.value.push(page);

		pages.value = pages.value.sort((a, b) => a.order - b.order);
		haveComponentsBeenFetched(page._id).value = true;
		getFullComponents(page._id).value = [];

		if (parent) {
			parent.children.push(page);
		}

		getPosthog().capture("page_create", {
			page_id: page._id,
		});

		PageSyncPipeline.syncPageCreate(page);
		setTimeout(() => {
			pageUpdatePipeline.markComplete(page._id);
		}, 1000 * 2.5); //wait 500ms before marking complete (a bit hacky, but it works lol)

		if (redirect) {
			navigateTo(`/page/${page._id}`);
		}
		return page;
	};

	return {
		pages,
		fetchPages,
		createPage,
		getParent,
		getComponent,
		duplicatePage,
		loadingPages,
		haveComponentsBeenFetched,
		parseFetchedPagesData,
		getFullComponents,
		getPage,
		preloadComponents,
		getRecents,
		specialComponents,
		getAllComponents,
		getComponentSolo,
		tryGetLocalComponent,
		getComponentSoloNoPromise,
		RecentFilters,
		getParentPage,
	};
};
