import { useLocalStorage } from "@vueuse/core";
import { PageSyncPipeline } from "~/utils/sync/page_sync_pipeline";
import PageModification = PageSyncPipeline.PageModification;
import ComponentContentUpdate = PageSyncPipeline.ComponentContentUpdate;

export const usePageSyncPipeline = () => {
	const storage = useLocalStorage<PageModification<any>[]>("page_sync_pipeline_page_modifications", [], {
		deep: true,
	});
	const componentUpdateQueue = useLocalStorage<ComponentContentUpdate[]>("page_sync_pipeline_component_update_queue", [], {
		deep: true,
	});

	return {
		storage,
		componentUpdateQueue
	};
};
