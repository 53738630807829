export enum Plan {
	FREE = "FREE",
	PLUS = "PLUS",
	ULTIMATE = "ULTIMATE",
	ENTERPRISE = "ENTERPRISE",
}

export interface User {
	_id: string;
	name: string;
	username: string;
	profilePictureUrl: string;
	onboardingComplete: boolean;
	email: string;
	orginization: string;
	admin: boolean;
	componentCount: number;
	lastActive: Date;
	hasReceivedFreeTrial: boolean;

	plan: {
		type: Plan;
		subscriptionId: string;
		createdAt: Date;
		updatedAt: Date;
		endAt: Date;
		cancelled: boolean;
		cancelledAt: Date;
		isFreeTrial: boolean;
	};
}

export function isPlanActive() {
	const { user } = useAuth();

	if (!user.value) return false;
	if (!user.value?.plan) return false;

	const plan = user.value?.plan;

	if (!plan) return false;
	if (plan.endAt && new Date(plan.endAt) < new Date()) return false;

	return !plan.cancelled;
}

export function getCurrentActivePlanFull() {
	const { user } = useAuth();

	if (!user.value) return null;
	if (!user.value!.plan) return null;

	if (!isPlanActive()) return null; // If the plan is not active, return the free plan

	return user.value?.plan;
}

export function getCurrentActivePlan() {
	const active = getCurrentActivePlanFull();

	return active ? active.type : Plan.FREE;
}

export async function sendToLastPage() {
	const { pages } = usePages();
	const { getPreviousSessionRoute } = useAuth();

	const lastRoute = getPreviousSessionRoute().value;
	if (lastRoute === "home") {
		await navigateTo("/home");
		return;
	}

	if (lastRoute === "assistant") {
		await navigateTo("/assistant");
		return;
	}

	await navigateTo("/home");

	// if (lastRoute) {
	// 	await navigateTo("/page/" + lastRoute); //page route automatically handles null or undefined pages
	// 	return;
	// }
	//
	// const firstPage = pages.value.filter((p) => !p.archived).sort((a, b) => new Date(a.lastOpened).getTime() - new Date(b.lastOpened).getTime())[0];
	//
	// if (firstPage) {
	// 	await navigateTo("/page/" + firstPage._id);
	// } else {
	//
	// }
}
