import { Component, ComponentType } from "~//utils/page";
import type { ImageComponent, Page } from "~/utils/page";
import { useFavorites } from "~/composables/useFavorites";
//@ts-ignore
import { TextComponentContent, TextCreator, TextSubType } from "@scholarly/components";
import ObjectID from "bson-objectid";
import { componentUpdatePipeline } from "~/utils/pipeline/model_update_pipeline";
import { getPosthog } from "~/utils/posthog_utils";
import { recordPageAction } from "~/utils/history/page_edit_history";
import { PageSyncPipeline } from "~/utils/sync/page_sync_pipeline";
import axios from "axios";

export const SUPPORTED_IMAGE_COMPONENT_MIME_TYPES = ["image/jpeg", "image/png", "image/gif", "image/bmp", "image/webp", "image/tiff", "application/pdf"];

export const SUPPORTED_IMAGE_COMPONENT_MIME_TYPES_AS_STRING = SUPPORTED_IMAGE_COMPONENT_MIME_TYPES.join(", ");

export async function markComponentOpened(component: Component) {
	component.lastOpenedAt = new Date();

	try {
		await axios.post(`/pages/components/${component._id}/mark_recently_opened`);
	} catch (e) {
		console.error(e);
	}
}

const LIMIT_TO_WHERE_WE_SHOW_DELETE_MODAL_FOR_TEXT_COMPONENTS = 3;

export async function deleteComponent(components: Component[], page: Page, toDelete: Component[] | Component, showAlert: boolean = true, ignoreDeleteModal = false) {
	const { favorites } = useFavorites();
	const deletionConfirmMenu = useComponentDeletionConfirm();
	const toDeleteArray = Array.isArray(toDelete) ? toDelete : [toDelete];

	if (!ignoreDeleteModal) {
		//check to see if any of the components are of image, or flashcards type
		//if so, set the value of this to true
		let isPreciousComponent = false;

		for (const component of toDeleteArray) {
			if (component.type === ComponentType.IMAGE || component.type === ComponentType.FLASH_CARDS) {
				isPreciousComponent = true;
				break;
			}
		}

		if (
			Array.isArray(toDelete) &&
			toDelete.filter((component) => component.type === ComponentType.TEXT).length >= LIMIT_TO_WHERE_WE_SHOW_DELETE_MODAL_FOR_TEXT_COMPONENTS
		) {
			isPreciousComponent = true;
		}

		if (isPreciousComponent) {
			deletionConfirmMenu.toDelete.value = toDeleteArray;
			return;
		}
	}

	// This is where deletes are actually handled
	let index = 0;
	for (const component of toDeleteArray) {
		index = components.indexOf(component);
		components.splice(index, 1);

		page.components.splice(index, 1);

		favorites.value = favorites.value.filter((favorite) => favorite.itemId !== component._id);
	}

	recordPageAction(page, {
		name: "component_delete",
		undo: async () => {
			for (let component of toDeleteArray) {
				createComponent(component.type, page, components, {
					content: component.content,
					insertAfter: components[index],
				});
			}
		},
		redo: () => {
			//todo: do this
		},
	});

	const { user } = useAuth();

	if (user.value) {
		if (Array.isArray(toDelete)) {
			user.value.componentCount -= toDelete.length;
		} else {
			user.value.componentCount--;
		}
	}

	getPosthog().capture("component_delete", {
		user_id: user.value?._id,
	});

	PageSyncPipeline.syncComponentDeleteMultiple(page, !Array.isArray(toDelete) ? [toDelete._id] : toDelete.map((component) => component._id));
}

export function addComponent(page: Page, components: Component[], component: Component | Component[]) {
	const toAdd: Component[] = Array.isArray(component) ? component : [component];
	const toAddIds = toAdd.map((c) => c._id);

	page.components.push(...toAddIds);
	components.push(...toAdd);
}

export function insertComponent(page: Page, components: Component[], component: Component | Component[], index: number) {
	const toInsert = Array.isArray(component) ? component : [component];
	const toInsertIds = toInsert.map((c) => c._id);

	page.components.splice(index, 0, ...toInsertIds);
	components.splice(index, 0, ...toInsert);
}

interface ComponentCreateOptions {
	content: any;
	insertAfter?: Component;
	focus?: boolean;
}

export function emptyTextContent(): TextComponentContent {
	return {
		text: new TextCreator().get(),
		subType: TextSubType.NORMAL,
		metadata: {
			indents: 0,
		},
	};
}

export function emptyFlashcardsContent(): {} {
	return {
		title: "Untitled Flashcards",
		cards: [],
		minimized: false,
	};
}

function findId(components: Component[]): string {
	//ObjectID is accurate up to the second, so we need to add a buffer to ensure uniqueness (As we have a lot of components being created at once)
	const time_buffer = Math.floor(Math.random() * 1000 * 60 * 5); //Add up to 5 minute of buffer, if two people create a component at the same time, we might be in trouble but it should be fine
	const id = ObjectID(Date.now() + time_buffer).toHexString();

	if (components.find((component) => component._id.toString() === id.toString())) {
		return findId(components); //Keep trying
	}

	return id;
}

export function createComponent(type: ComponentType, page: Page, components: Component[], options: ComponentCreateOptions) {
	const id = findId(components);

	const component: Component = {
		_id: id,
		type: type,
		content: options.content,
		createdAt: new Date(),
		modifiedAt: new Date(),
		createdBy: "", //not needed
		focus: options.focus ?? true,
	};
	componentUpdatePipeline.markPending(component._id);

	//add component to the page
	if (options.insertAfter) {
		insertComponent(page, components, component, components.indexOf(options.insertAfter) + 1);
	} else {
		addComponent(page, components, component);
	}
	PageSyncPipeline.syncComponentCreate(page, component);
	getPosthog().capture("component_create", {
		component: id,
		type: type,
	});

	const found = components.find((c) => c._id === id)!!;

	if (component.type === ComponentType.FLASH_CARDS || ComponentType.IMAGE) {
		if (usePages().specialComponents.value.find((c) => c._id === component._id)) {
			return found;
		}
		usePages().specialComponents.value.push(found);
	}

	return found;
}

export async function uploadSoloFile(file: File): Promise<ImageComponent> {
	const pages = usePages();
	const formData = new FormData();

	formData.append("file", file);

	const { data } = await axios.post("/pages/upload_solo", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	const component = data.component;

	usePages().specialComponents.value.push(component);

	return component;
}

export function isPDF(component: ImageComponent | Component) {
	if (!component.content.file) return false;
	return component.content.file.id.endsWith(".pdf");
}

// Transaction, delays the "push" of multiple components to improve performance
class ComponentCreateTransaction {
	page: Page;
	pageComponents: Component[];
	insertAfter?: Component;

	newComponents: Component[];

	constructor(page: Page, components: Component[], insertAfter?: Component) {
		this.page = page;
		this.pageComponents = components;
		this.newComponents = [];
		this.insertAfter = insertAfter;
	}

	add(type: ComponentType, content: any) {
		const id = findId(this.pageComponents);

		const component: Component = {
			_id: id,
			type: type,
			content: content,
			createdAt: new Date(),
			modifiedAt: new Date(),
			createdBy: "",
			focus: false,
		};

		componentUpdatePipeline.markPending(component._id);
		this.newComponents.push(component);

		getPosthog().capture("component_create", {
			component: id,
			type: type,
		});
	}

	finish() {
		for (const component of this.newComponents) {
			if (component.type === ComponentType.FLASH_CARDS || ComponentType.IMAGE) {
				if (!usePages().specialComponents.value.find((c) => c._id === component._id)) {
					usePages().specialComponents.value.push(component);
				}
			}
			PageSyncPipeline.syncComponentCreate(this.page, component);
		}

		if (this.insertAfter) {
			insertComponent(this.page, this.pageComponents, this.newComponents, this.pageComponents.indexOf(this.insertAfter) + 1);
		} else {
			addComponent(this.page, this.pageComponents, this.newComponents);
		}
	}
}

export function createComponentTransaction(
	page: Page,
	components: Component[],
	options: {
		insertAfter?: Component;
	},
) {
	return new ComponentCreateTransaction(page, components, options.insertAfter);
}
