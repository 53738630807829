<template>
	<Transition name="sidebar-slide">
		<slot />
	</Transition>
</template>
<style>
.sidebar-slide-enter-active {
	transition: margin-left 0.2s;
}

.sidebar-slide-enter-from {
	margin-left: -230px;
}

.sidebar-slide-enter-to {
	margin-left: 0;
}

/* Leave */

.sidebar-slide-leave-active {
	transition: margin-left 0.2s;
	overflow: hidden;
}

.sidebar-slide-leave {
	margin-left: 0;
}

.sidebar-slide-leave-to {
	margin-left: -230px;
}
</style>
