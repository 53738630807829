<template>
	<div
		v-if="search"
		class="px-4">
		<h1 class="pt-2 text-base font-semibold text-stone-600">Search Results</h1>
		<div class="mt-1 flex w-full flex-col divide-y divide-gray-100">
			<div
				v-for="x in 5"
				v-if="isSearching"
				class="my-1 animate-pulse rounded-md bg-gray-100 px-4 py-0">
				<FileIcon class="h-5 w-5 text-transparent" />
				<div>
					<h1 class="text-base font-semibold text-transparent">Loading...</h1>
					<div class="flex flex-row items-center gap-2">
						<p class="text-xs text-transparent">Load 2</p>
						<div class="h-1 w-1 rounded-full bg-gray-100"></div>
						<p class="text-xs text-transparent">Load 3</p>
					</div>
				</div>
			</div>
			<div
				v-for="item in results"
				v-else
				:class="{ 'bg-gray-100': focusedBtn === item._id }"
				:data-content-search-option-id="item._id"
				class="flex w-full flex-row items-center gap-3 rounded-md px-4 py-2.5 hover:bg-gray-100"
				role="button"
				@click.prevent="handleNav(item.type, item.url)">
				<div>
					<FileIcon
						v-if="item.type === 'page'"
						class="h-5 w-5 text-gray-400" />
					<FileTextIcon
						v-if="item.type === 'image'"
						class="h-5 w-5 text-red-500" />
					<flashcards-icon
						v-if="item.type === 'flash_cards'"
						class="h-5 w-5 text-secondary" />
				</div>
				<div>
					<h1 class="text-base font-semibold text-stone-600">
						{{ item.title ? item.title : "Untitled" }}
					</h1>
					<p
						v-if="item.aiDescription"
						class="text-xs text-gray-500">
						{{ shortenText(item.aiDescription, 220) }}
					</p>
					<div class="flex flex-row items-center gap-2">
						<p class="text-xs text-gray-600">
							{{ item.type === "image" ? "PDF" : item.type === "flash_cards" ? "Flashcard" : item.type === "page" ? "Page" : item.type }}
						</p>
						<div class="h-1 w-1 rounded-full bg-gray-400"></div>
						<p class="text-xs text-gray-600">
							{{ getLastOpenedOrOwned(item._id) }}
							<!--								{{ timeAgoPretty(item.model.lastOpened ? item.model.lastOpened : item.model.lastOpenedAt) }}-->
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<h1 class="mx-8 mt-4 text-base font-semibold">Search for something using natural language and get results.</h1>
	</div>
</template>
<script lang="ts" setup>
import { FileIcon, FileTextIcon } from "lucide-vue-next";
import { RecentItem } from "~/utils/recents";
import axios from "axios";
import { fastStringSearch } from "~/utils/search_utils";
import { ComponentType } from "~/utils/page";

const MAX_SEARCH_RESULTS = 10;
const { getRecents } = usePages();

const emits = defineEmits(["close", "mount"]);

const props = defineProps<{
	search: string;
	focusedBtn: string | null;
}>();

const { searchScope } = useContentSearch();

const results = ref<
	{
		_id: string;
		title: string;
		url: string;
		type: "page" | ComponentType;
		aiDescription?: string;
	}[]
>([]);
const isSearching = ref(false);

let needsToSearch = false;
let lastTypeMs = Date.now();
let interval: NodeJS.Timeout | null = null;

watch(
	() => props.search,
	(value) => {
		if (value) {
			needsToSearch = true;
			lastTypeMs = Date.now();
		}
	},
);

watch(searchScope, () => {
	if (props.search) {
		doAISearch();
	}
});

onMounted(() => {
	if (props.search) {
		doAISearch();
		needsToSearch = false;
	}

	interval = setInterval(() => {
		if (needsToSearch && Date.now() - lastTypeMs > 300) {
			doAISearch();
			needsToSearch = false;
		}
	}, 1);

	emits("mount");

	getPosthog().capture("content_search_ai_mode_mounted", {});
});

onUnmounted(() => {
	if (interval) {
		clearInterval(interval);
	}
});

const filteredRecents = computed(() => {
	const recents = getRecents.value.filter((x) => x.type === "PDFs" || x.type === "flashcards" || x.type === "pages");

	if (!props.search) return recents.slice(0, MAX_SEARCH_RESULTS);

	return fastStringSearch<RecentItem>(props.search, recents, (x) => x.title, {
		limit: MAX_SEARCH_RESULTS,
	});
});

function getLastOpenedOrOwned(elmId: string) {
	if (searchScope.value === "global") {
		const item = getRecents.value.find((x) => x.model._id === elmId);

		return !item ? "From another Scholarly User" : "From your library";
	} else {
		const item = getRecents.value.find((x) => x.model._id === elmId);

		if (!item) {
			return "";
		}

		return timeAgoPretty(item.model.lastOpened ? item.model.lastOpened : item.model.lastOpenedAt);
	}
}

async function doAISearch() {
	if (!props.search) return;
	if (isSearching.value) return;

	isSearching.value = true;

	getPosthog().capture("content_search_ai_mode_used_search", {});

	try {
		results.value = [];
		const { data } = await axios.post("/content_search/query_ai", {
			query: props.search,
			scope: searchScope.value,
			pageInfo:
				searchScope.value === "personal"
					? getRecents.value
							.filter((x) => x.type === "pages")
							.map((x) => {
								return { _id: x.model._id, title: x.title };
							})
					: [],
			limit: MAX_SEARCH_RESULTS,
		});

		for (const item of data) {
			let url: string = "";

			if (item.type === "page") {
				url = searchScope.value === "global" ? `/s/${item._id}` : `/page/${item._id}`;
			} else if (item.type === ComponentType.IMAGE) {
				url = `/pdf/${item._id}`;
			} else if (item.type === ComponentType.FLASH_CARDS) {
				url = `/flashcard/${item._id}`;
			}
			let title = item.title;

			// We gotta figure out titles for pdfs/flashcards as they're not sent over
			if (!title) {
				getRecents.value.forEach((recent) => {
					if (recent.model._id === item._id) {
						title = recent.title;
					}
				});
			}

			results.value.push({
				_id: item._id,
				title: title,
				url: url,
				type: item.type,
				aiDescription: item.aiDescription,
			});
		}

		for (const recent of filteredRecents.value) {
			if (results.value.length >= MAX_SEARCH_RESULTS) {
				break;
			}
			if (results.value.find((x) => x._id === recent.model._id)) continue;

			let type: any = "";
			let url: string = "";

			if (recent.type === "PDFs") {
				type = ComponentType.IMAGE;
				url = `/pdf/${recent.model._id}`;
			} else if (recent.type === "flashcards") {
				type = ComponentType.FLASH_CARDS;
				url = `/flashcard/${recent.model._id}`;
			} else if (recent.type === "pages") {
				type = "page";
				url = searchScope.value === "global" ? `/s/${recent.model._id}` : `/page/${recent.model._id}`;
			}

			results.value.push({
				_id: recent.model._id,
				title: recent.title,
				url: url,
				type: type,
			});
		}
	} catch (e) {
		addAlert("An error occurred while searching", "error");
		console.error(e);
	} finally {
		isSearching.value = false;
	}
}

function handleNav(type: any, url: string) {
	if (searchScope.value === "global") {
		navigateTo(url, {
			open: {
				target: "_blank",
			},
		});

		getPosthog().capture("content_search_ai_mode_used_result_click_global", { type: type });
	} else {
		navigateTo(url);
	}
	getPosthog().capture("content_search_ai_mode_used_result_click", { type: type });
	emits("close");
}
</script>
