import axios from "axios";

export interface StreamOptions {
	data?: object;
	handler: (data: any) => void;
}

export async function streamGET(url: string, options: StreamOptions) {
	return new Promise<void>(async (resolve, reject) => {
		const { tempToken, getAuthToken } = useAuth();

		const data = options.data ? JSON.stringify(options.data) : "";

		try {
			const response = await fetch(`${axios.defaults.baseURL}${url}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${tempToken.value !== null ? tempToken.value : getAuthToken().value}`,
					Accept: "event-stream",
				},
				//@ts-ignore
				response: "stream",
			});

			//if error
			if (!response.ok) {
				reject(response);
				return;
			}

			//@ts-ignore
			const reader = response.body.getReader();

			while (true) {
				const { done, value } = await reader.read();
				if (done) {
					break;
				}

				//turn value to string
				const decoder = new TextDecoder("utf-8");
				const valueString = decoder.decode(value);

				options.handler(valueString);
			}

			resolve();
		} catch (e) {
			reject(e);
		}
	});
}


export async function streamPOST(url: string, options: StreamOptions) {
	return new Promise<void>(async (resolve, reject) => {
		const { tempToken, getAuthToken } = useAuth();

		const data = options.data ? JSON.stringify(options.data) : "";

		try {
			const response = await fetch(`${axios.defaults.baseURL}${url}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${tempToken.value !== null ? tempToken.value : getAuthToken().value}`,
					Accept: "event-stream",
				},
				body: data,
				//@ts-ignore
				response: "stream",
			});

			//if error
			if (!response.ok) {
				reject(response.statusText);
				return;
			}

			//@ts-ignore
			const reader = response.body.getReader();

			while (true) {
				const { done, value } = await reader.read();
				if (done) {
					break;
				}

				//turn value to string
				const decoder = new TextDecoder("utf-8");
				const valueString = decoder.decode(value);

				options.handler(valueString);
			}

			resolve();
		} catch (e) {
			reject(e);
		}
	});
}
