export const FREE_PLAN = {
	text_completions: 250,
	pdf_to_flashcard_pages: 5,
	assistant_messages: 10,
	max_pdf_insights: 5,
	max_flashcard_insights: 5,
};

export const PRO_PLAN = {
	text_completions: 250,
	pdf_to_flashcard_pages: 15,
	assistant_messages: 100,
	max_pdf_insights: 10,
	max_flashcard_insights: 10,
};

export const ULTIMATE_PLAN = {
	text_completions: Infinity,
	pdf_to_flashcard_pages: 50,
	assistant_messages: Infinity,
	max_pdf_insights: Infinity,
	max_flashcard_insights: Infinity,
};
