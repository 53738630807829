<template>
	<div class="mt-4">
		<div class="flex flex-row items-center justify-between">
			<div class="flex flex-row items-center gap-2">
				<h3 class="text-base font-bold text-gray-800">Content</h3>
			</div>
			<div class="flex flex-row items-center gap-1">
				<tooltip tooltip="Create a new page">
					<dropdown-container>
						<dropdown-open-button
							aria-label="Create Page"
							class="rounded-md border border-gray-100 bg-white p-1 text-black shadow-sm transition-colors hover:bg-gray-100">
							<PlusIcon class="h-[18px] w-[18px]" />
						</dropdown-open-button>
						<template #dropdown>
							<dropdown-item
								@click="createPage()"
								:icon="LogoMiniCircular">
								Create Page
							</dropdown-item>
							<dropdown-item
								@click="createFlashcardsPage()"
								:icon="FlashcardsIcon">
								Create Flashcard Deck
							</dropdown-item>
							<dropdown-item
								@click="uploadPDFInputFn()"
								:icon="UploadIcon">
								Upload PDF
							</dropdown-item>
							<dropdown-item
								@click="toggleSettingsModal('import')"
								:icon="ArrowDownTrayIcon">
								Import
							</dropdown-item>
						</template>
					</dropdown-container>
				</tooltip>
				<!--				<tooltip tooltip="Cleanup Unused Pages">-->
				<!--					<button-->
				<!--						class="ai-tool-color rounded-md p-1 text-black hover:bg-gray-100"-->
				<!--						@click.prevent="isMassArchive = true">-->
				<!--						<BroomIcon class="h-[18px] w-[18px]" />-->
				<!--					</button>-->
				<!--				</tooltip>-->
			</div>
		</div>
		<div class="space-y-0.5">
			<div
				v-for="content in getContent.slice(0, showAll ? getContent.length : MINIMIZED_SHOW_AMOUNT)"
				:key="content.url">
				<nuxt-link
					:to="content.url"
					class="flex flex-row items-center gap-2 rounded-md px-2 py-1.5 text-sm font-medium text-gray-500 transition-colors hover:bg-gray-150">
					<pdf-icon
						v-if="content.type === 'pdf'"
						class="h-4 w-4" />
					<span v-if="content.emoji">
						{{ content.emoji }}
					</span>
					<span v-if="content.type === 'page_flashcard'">
						<FlashcardsIcon class="h-3 w-3 text-purple-600" />
					</span>
					<span> {{ shortenText(content.title, 23) }} </span>
				</nuxt-link>
			</div>
			<button
				@click="showAll = !showAll"
				class="flex flex-row items-center space-x-2 px-2 py-2 text-sm font-semibold text-gray-700">
				<span>
					{{ !showAll ? "Show All" : "Hide" }}
				</span>
				<ArrowRight class="h-3 w-3" />
			</button>
		</div>
		<solo-pdf-component-upload
			:drag-and-drop="false"
			@open-callback="(args) => (uploadPDFInputFn = args)" />
	</div>
</template>
<script lang="ts" setup>
import { FlashcardsIcon, LogoMiniCircular } from "#components";
import { ArrowRight, PlusIcon, UploadIcon } from "lucide-vue-next";
import { ArrowDownTrayIcon } from "@heroicons/vue/24/outline";
import { createComponent } from "~/utils/component/component_utils";
import { PageSyncPipeline } from "~/utils/sync/page_sync_pipeline";
import { createOnboardingPDF } from "~/utils/onboarding_default_pages";

const MINIMIZED_SHOW_AMOUNT = 10;

const { pages, specialComponents, getParentPage, createPage, getFullComponents } = usePages();
const { toggleSettingsModal } = useUserSettings();

let uploadPDFInputFn = () => {};
const showAll = ref(false);

const getContent = computed(() => {
	const content: {
		_id: string;
		type: "page" | "page_flashcard" | "pdf";
		title: string;
		emoji: string | undefined | null;
		url: string;
		pageComponentsLength?: number;
		createdAt: Date;
	}[] = [];

	for (const page of pages.value) {
		if (page.archived) continue;

		const set = new Set(page.components);

		if (set.size === 1) {
			const component = specialComponents.value.find((c) => c._id.toString() === page.components[0].toString());

			if (component && component.type === ComponentType.FLASH_CARDS) {
				content.push({
					_id: page._id,
					type: "page_flashcard",
					emoji: page.emoji,
					title: page.title || "Untitled Page",
					url: `/page/${page._id}/flashcard/${component._id}`,
					createdAt: new Date(page.createdAt),
				});
				continue;
			}
		}

		content.push({
			_id: page._id,
			type: "page",
			emoji: page.emoji,
			title: page.title || "Untitled Page",
			pageComponentsLength: set.size,
			url: `/page/${page._id}`,
			createdAt: new Date(page.createdAt),
		});
	}

	for (const component of specialComponents.value) {
		switch (component.type) {
			case "image":
				if (!component.content.file) continue;
				if (component.content.file.id.endsWith(".pdf")) {
					const page = getParentPage(component);

					if (page) continue;

					content.push({
						_id: component._id,
						emoji: undefined,
						type: "pdf",
						title: (component as ImageComponent).content.file?.name || "Untitled PDF",
						url: `/pdf/${component._id}`,
						createdAt: new Date(component.createdAt),
					});
				}
				break;
		}
	}

	return content.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
});

function createFlashcardsPage() {
	const page = createPage(undefined, "", false);
	const component = createComponent(ComponentType.FLASH_CARDS, page, getFullComponents(page._id).value, {
		content: {
			title: "",
			cards: [],
			minimized: false,
		},
	});
	PageSyncPipeline.syncComponentContentUpdate(page, component);
	navigateTo(`/page/${page._id}/flashcard/${component._id}`);
}
</script>
<style scoped>
.router-link-exact-active {
	@apply bg-gray-150 font-semibold text-black;
}
</style>
